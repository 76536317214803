import React, { useEffect, useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { AddressBook } from 'ui/component/checkout/components/address-form/address-book';
import { AddressSummary } from 'ui/component/checkout/components/address-form/address-summary';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import {
    cartIdVar,
    cartVar,
    cartShippingAddressVar,
    defaultShippingAddressVar,
    getInitialAddressState,
    isShippingAddressSetVar,
    cartAvailableShippingMethodsVar,
    showVerifyAddressVar,
    validatedAddressVar,
    originalAddressVar,
    shouldValidateAddressVar,
    paymentMethodsVar,
} from 'ui/page/checkout-page/checkout-state';
import { SET_SHIPPING_ADDRESS } from 'graphql/cart/shipping-address';
import { ShippingMethodForm } from './shipping-method-form';
import { VerifyAddress } from './verify-address';

export const ShippingAddressForm = () => {
    const cartId = useReactiveVar(cartIdVar);
    const isShippingAddressSet = useReactiveVar(isShippingAddressSetVar);
    const cartShippingAddress = useReactiveVar(cartShippingAddressVar);
    const showVerifyAddress = useReactiveVar(showVerifyAddressVar);
    const validatedAddress = useReactiveVar(validatedAddressVar);
    const originalAddress = useReactiveVar(originalAddressVar);
    const defaultShippingAddress = useReactiveVar(defaultShippingAddressVar);
    const [error, setError] = useState<string|null>(null);
    const initialAddress = getInitialAddressState(defaultShippingAddress);
    const [shippingAddress, setShippingAddress] = useState(initialAddress);
    const [saveShippingAddress, { loading }] = useMutation(SET_SHIPPING_ADDRESS, {
        onCompleted: (data) => {
            const availableShippingMethods =
                data?.setShippingAddressesOnCart?.cart?.shipping_addresses?.[0]?.available_shipping_methods;
            if (availableShippingMethods) {
                cartAvailableShippingMethodsVar(availableShippingMethods);
            }
            cartVar(data.setShippingAddressesOnCart.cart);
            paymentMethodsVar(data.setShippingAddressesOnCart.cart.available_payment_methods);
            isShippingAddressSetVar(true);
            if (data?.setShippingAddressesOnCart) {
                cartShippingAddressVar({ // TODO This is a bit clunky and should be refactored
                    ...shippingAddress,
                    id: '',
                    country: { code: shippingAddress.countryCode },
                    country_code: shippingAddress.countryCode,
                    region:
                        {
                            code: shippingAddress.region,
                            region_code: shippingAddress.region,
                            region: shippingAddress.region,
                        },
                });
            }
        },
        onError: (error) => {
            setError(error.message.toString());
        },
    });
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }
        shouldValidateAddressVar(true);
        saveShippingAddress({
            variables: {
                cartId,
                ...shippingAddress,
            },
        });
    };
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setShippingAddress({ ...shippingAddress, [event.target.name]: value });
    };
    useEffect(() => {
        if (cartShippingAddress?.country?.code) { // Only set a valid cartShippingAddress
            setShippingAddress(getInitialAddressState(cartShippingAddress));
        }
    }, [cartShippingAddress]);
    return (
        <div data-test="shipping-address">
            {!showVerifyAddress && isShippingAddressSet && (
                <>
                    <AddressSummary
                        address={shippingAddress}
                        title="Shipping Address"
                        addressIsSetVar={isShippingAddressSetVar}
                        format="shipping"
                    />
                    <ShippingMethodForm />
                </>
            )}
            {!showVerifyAddress && !isShippingAddressSet && (
                <AddressBook
                    address={shippingAddress}
                    name="shipping"
                    handleSubmit={handleSubmit}
                    onChange={onChange}
                    loading={loading}
                    setAddress={setShippingAddress}
                    isShippingAddressSet={isShippingAddressSetVar}
                />
            )}
            {showVerifyAddress && validatedAddress && originalAddress && (
                <VerifyAddress />
            )}
            { error && <div className={fStyles.error}>{error}</div> }
        </div>
    );
};
